import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/home";
import Tutor from "./pages/Tutor/tutor";
import Learning from "./pages/Learnings/learning";
import Staffing from "./pages/Staffing/staffing";
import Resume from "./pages/Resume/resume";
import ContactUsPage from "./pages/Contact/contact";
import TermsAndConditions from "./pages/Agreements/Terms";
import Privacypolicy from "./pages/Agreements/privacy";
import Disclaimer from "./pages/Agreements/Disclaimer";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/jobways-teach" element={<Tutor />} />
          <Route path="/e-learning" element={<Learning />} />
          <Route path="/staffing-recruitment" element={<Staffing />} />
          <Route path="/resume-marketing" element={<Resume />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
          <Route path="/Privacypolicy" element={<Privacypolicy />} />
          <Route path="/Disclaimer" element={<Disclaimer />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
