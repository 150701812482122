import React from "react";

const Disclaimer = () => {
  return (
    <div className="terms-container">
      <h1>Disclaimer</h1>
      <div className="terms-content">
        <p>
          <b>1.1</b> JOBWAYS.COM DOES NOT ACCREDITATION THE INFORMATION ON THIS
          WEBSITE, WHICH IS PROVIDED &quot;AS IS&quot; WITHOUT ACCREDITATION Y
          OF ANY KIND, INCLUDING, WITHOUT LIMITATION, ANY ACCREDITATION Y OF
          DESIGN, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, AND
          WITHOUT ACCREDITATION Y AS TO NON-INFRINGEMENT OR THE PERFORMANCE OR
          RESULTS USER MAY OBTAIN BY USING THE INFORMATION.
        </p>
        <p>
          <b>1.2</b> THE ENTIRE RISK AS TO THE RESULTS AND PERFORMANCE OBTAINED
          FROM USING THE INFORMATION ON THIS WEBSITE IS ASSUMED BY USER.
        </p>
        <p>
          <b>1.3</b> JOBWAYS.COM DOES NOT ASSUME RESPONSIBILITY FOR ANY ERRORS
          OR OMISSIONS IN THE INFORMATION OR DOCUMENTS WHICH ARE REFERENCED BY
          OR LINKED TO THIS WEBSITE.
        </p>
        <h2>2. General Disclaimer</h2>
        <p>
          <b>2.1</b> We have no mechanism to control comments/discussions posted
          on the site and, as such, we cannot guarantee in any manner the
          reliability, validity, accuracy, or truthfulness of such contents.
        </p>
        <p>
          <b>2.2</b> User also understands that by using the site user may be
          exposed to submitted contents which user may consider offensive,
          indecent, or objectionable.
        </p>
        <p>
          <b>2.3</b> User hereby agree to indemnify and hold the company
          harmless from and against any and all claims, notices, and actions
          that user may have arising out of user access or use of any submitted
          content.
        </p>
        <p>
          <b>2.4</b> Those who choose to access or use the company products and
          services from other locations, including from outside USA, may do so
          on their own initiative and are responsible for compliance with local
          legislations, if and to the extent local legislations are applicable.
        </p>
        <p>
          <b>2.5</b> Access to or use of the company products and services from
          jurisdictions where the contents or practices of the company products
          are illegal, unauthorized, or penalized is strictly prohibited.
        </p>
        <h2>3. Legal Disclaimer</h2>
        <p>
          <b>3.1</b> We reserve the right to disclose user personal information
          as required by legislation and when we believe that disclosure doing
          so in the company's interest to protect its property or other legal
          rights or the rights or property of others.
        </p>
        <h2>4. Accreditation y Disclaimer</h2>
        <p>
          <b>4.1</b> User understand that when using the company products and
          services, participating in a course, or accessing jobs, resumes,
          company content, or submitted content, user may be exposed to
          products, photographs, music, artwork, messages, and other materials
          from a variety of sources, and that company is not responsible for the
          accuracy, integrity, quality, legality, usefulness, safety of or
          relating to such products, content, or materials.
        </p>
        <p>
          <b>4.2</b> User further understand and acknowledge that user may be
          exposed to products, content, or materials that may be inaccurate,
          offensive, indecent, or objectionable, and user agree to waive, and
          hereby do waive, any legal or equitable rights or remedies user have
          or may have against company with respect thereto.
        </p>
        <p>
          <b>4.3</b> To the fullest extent permissible pursuant to applicable
          legislation, company and its affiliates, partners, licensors, and
          suppliers hereby disclaim all express, implied, and statutory
          accreditation ies of any kind, including, but not limited to, the
          implied accreditation ies of merchantability, fitness for a particular
          purpose, and non-infringement.
        </p>
        <p>
          <b>4.4</b> No advice or information, whether oral or written, obtained
          by user from company, an employee or representative of company or
          through the services will create any accreditationy not expressly
          stated herein.
        </p>
        <p>
          <b>4.5</b> Company and its affiliates, partners, licensors, and
          suppliers do not accreditation that the services or any part thereof,
          or any products or content offered through the services, will be
          uninterrupted, or free of errors, viruses, or other harmful components
          and do not accreditation that any of the foregoing will be corrected.
        </p>
        <p>
          <b>4.6</b> User understand and agree that user use, access, download,
          or otherwise obtain information, materials, or data through the
          company products, any associated sites or applications, and any third
          party sites at userr own discretion and risk and that user will be
          solely responsible for any damage to userr property (including userr
          computer system used in connection with the company products and
          services) or loss of data that results from the download or use of
          such material or data.
        </p>
      </div>
    </div>
  );
};

export default Disclaimer;
