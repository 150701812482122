import React, { useState } from "react";
import "./header.css";
import { Link } from "react-router-dom";

const Header = () => {
  const [isNavVisible, setNavVisibility] = useState(false);

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  return (
    <header className="header">
      <div className="logo">
        <a href="/">
          <img src="images/footer-logo.png" alt="Logo" />
        </a>
      </div>
      <button className="nav-toggle" onClick={toggleNav}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </button>
      <nav className={`navigation ${isNavVisible ? "visible" : ""}`}>
        <ul>
          <li>
            <Link to="/">HOME</Link>
          </li>
          <li>
            <Link to="/staffing-recruitment">STAFFING AND RECRUITMENT</Link>
          </li>
          <li>
            <Link to="/e-learning">E-LEARNINGS</Link>
          </li>
          <li>
            <Link to="/jobways-teach">JOBWAYS TEACH</Link>
          </li>
          <li>
            <Link to="/resume-marketing">RESUME MARKETING</Link>
          </li>
          <li>
            <Link to="/contact-us">CONTACT US</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
