import React from "react";

const Privacypolicy = () => {
  return (
    <div className="terms-container">
      <h1>Privacy Policy</h1>
      <div className="terms-content">
        <h2>1. ABOUT COMPANY – JOBWAYS</h2>
        <p>
          <b>1.1</b> ANY REFERENCE TO "WE", "US", "OUR WEB APPLICATION” ARE
          REFERENCES TO THE WWW.JOBWAYS.COM (THE "WEB APPLICATION") PRODUCT OF
          JOBWAYS LLC LOCATED AT 8 THE GREEN STE R · DOVER, DE 19901.
        </p>
        <p>
          <b>1.2</b> JOBWAYS.COM PROVIDES HYPERLINKS TO THE WEBSITES OF MANY
          OTHER COMPANIES TO WHICH THIS POLICY DOES NOT APPLY.
        </p>
        <p>
          <b>1.3</b> OTHER THAN THE REQUIREMENTS TO WHICH WE ADHERE UNDER THE
          PRIVACY SHIELD FRAMEWORK AND ITS ACCOUNTABILITY FOR ONWARD TRANSFER OF
          PERSONAL INFORMATION PRINCIPLE, WE CANNOT, AND DO NOT, ASSUME
          RESPONSIBILITY FOR THE PRIVACY OR INFORMATION POLICIES, PRACTICES OR
          ACTIONS OF ANY THIRD PARTIES.
        </p>
        <p>
          <b>1.4</b> THIS PRIVACY POLICY (THE "POLICY") GOVERNS INFORMATION USER
          PROVIDE TO US OR WE LEARN FROM USER USE OF THIS WEB APPLICATION (THE
          "WEB APPLICATION") AND CONSTITUTES A LEGAL AGREEMENT BETWEEN USER, AS
          THE USER OF THE WEB APPLICATION, AND THE COMPANY, AS THE OWNER OF THE
          WEB APPLICATION.
        </p>
        <p>
          <b>1.5</b> THE POLICY WILL ALSO TELL USER HOW WE MAY COLLECT, USE, AND
          IN SOME INSTANCES SHARE THIS INFORMATION.
        </p>
        <p>
          <b>1.6</b> OUR POLICIES DO NOT APPLY TO THIRD-PARTY WEB APPLICATION
          THAT ARE CONNECTED VIA HYPERLINKS TO OUR WEB APPLICATION AND MAY
          DIFFER FROM OTHER SERVICE OFFERINGS AND USER SHOULD CAREFULLY REVIEW
          THE TERMS OF SERVICE AND THIS PRIVACY NOTICE BEFORE USING THESE
          SERVICES.
        </p>

        <h2>2. AGE'S PRIVACY</h2>
        <p>
          <b>2.1</b> WE ARE COMMITTED TO PROTECTING CHILDREN'S PRIVACY ONLINE.
        </p>
        <p>
          <b>2.2</b> THIS WEB APPLICATION IS INTENDED FOR USERS ABOVE THE AGE OF
          13.
        </p>
        <p>
          <b>2.3</b> WE DO NOT KNOWINGLY COLLECT PAYMENT RELATED INFORMATION
          FROM CHILDREN.
        </p>

        <h2>3. BILLING</h2>
        <p>
          <b>3.1</b> IF USER USE OR PROVIDE SERVICES ON THE WEB APPLICATION FOR
          WHICH WE IMPLEMENT A BILLING SYSTEM FOR USER, WE WILL COLLECT
          ADDITIONAL INFORMATION FROM USER SO THAT WE CAN PROCESS AND COLLECT
          BILLING INFORMATION.
        </p>
        <p>
          <b>3.2</b> FOR EXAMPLE, WE MAY COLLECT USER MAILING ADDRESS TO REMIT
          PAYMENTS.
        </p>

        <p>
          <b>4.1</b> IF THE COMPANY SHOULD EVER FILE FOR BANKRUPTCY OR HAVE ITS
          ASSETS SOLD TO OR MERGE WITH ANOTHER ENTITY, INFORMATION THE COMPANY
          RECEIVES FROM USER FROM THIS WEB APPLICATION IS A COMPANY ASSET THAT
          MAY BE TRANSFERRED IN CONNECTION WITH THESE TYPES OF CORPORATE EVENTS.
        </p>

        <h2>5. COOKIES AND THIRD PARTY COOKIES</h2>
        <p>
          <b>5.1</b> THE WEB APPLICATION USES SOFTWARE TAGS CALLED "COOKIES" TO
          IDENTIFY CUSTOMERS WHEN THEY VISIT OUR WEB APPLICATION.
        </p>
        <p>
          <b>5.2</b> COOKIES ARE USED TO REMEMBER USER PREFERENCES AND MAXIMIZE
          PERFORMANCE OF OUR SERVICES.
        </p>
        <p>
          <b>5.3</b> THE INFORMATION WE COLLECT WITH COOKIES IS NOT SOLD,
          RENTED, OR SHARED WITH ANY OUTSIDE PARTIES.
        </p>
        <p>
          <b>5.4</b> USERS WHO DISABLE THEIR WEB BROWSER'S ABILITY TO ACCEPT
          COOKIES WILL BE ABLE TO BROWSE OUR WEB APPLICATION BUT MAY NOT BE ABLE
          TO SUCCESSFULLY USE OUR SERVICE.
        </p>
        <p>
          <b>5.5</b> WE USE BOTH SESSION ID COOKIES AND PERSISTENT COOKIES. A
          SESSION ID COOKIE EXPIRES WHEN USER CLOSE USER BROWSER.
        </p>
        <p>
          <b>5.6</b> A PERSISTENT COOKIE REMAINS ON USER HARD DRIVE FOR AN
          EXTENDED PERIOD OF TIME.
        </p>
        <p>
          <b>5.7</b> USER CAN REMOVE PERSISTENT COOKIES BY FOLLOWING DIRECTIONS
          PROVIDED IN USER INTERNET BROWSER'S "HELP" FILE.
        </p>
        <p>
          <b>5.8</b> PERSISTENT COOKIES ENABLE US TO TRACK AND TARGET THE
          INTERESTS OF OUR USERS TO ENHANCE THE EXPERIENCE ON OUR WEB
          APPLICATION.
        </p>
        <p>
          <b>5.9</b> THIS PRIVACY POLICY COVERS THE USE OF COOKIES BY OUR WEB
          APPLICATION ONLY AND DOES NOT COVER THE USE OF COOKIES BY ANY
          ADVERTISERS.
        </p>
        <p>
          <b>5.10</b> WE MAY FROM TIME TO TIME ENGAGE THIRD PARTIES TO TRACK AND
          ANALYZE NON-PERSONALLY IDENTIFIABLE USAGE AND VOLUME STATISTICAL
          INFORMATION FROM VISITORS TO OUR WEB APPLICATION TO HELP US ADMINISTER
          OUR WEB APPLICATION AND IMPROVE ITS QUALITY.
        </p>
        <p>
          <b>5.11</b> SUCH THIRD PARTIES MAY USE COOKIES TO HELP TRACK VISITOR
          BEHAVIOUR.
        </p>
        <p>
          <b>5.12</b> SUCH COOKIES WILL NOT BE USED TO ASSOCIATE INDIVIDUAL WEB
          SITE VISITORS TO ANY PERSONAL INFORMATION.
        </p>
        <p>
          <b>5.13</b> ALL DATA COLLECTED BY SUCH THIRD PARTIES ON OUR BEHALF IS
          USED ONLY TO PROVIDE US WITH INFORMATION ON WEB APPLICATION USAGE AND
          IS NOT SHARED WITH ANY OTHER THIRD PARTIES.
        </p>

        <h2>6. DISPUTE RESOLUTION AND GOVERNING LAW</h2>
        <p>
          <b>6.1</b> IF ANY DISPUTE ARISES BETWEEN THE COMPANY AND THE EMPLOYEE
          IN CONNECTION WITH OR ARISING OUT OF THE VALIDITY, INTERPRETATION,
          IMPLEMENTATION, OR ALLEGED BREACH OF ANY PROVISION OF THE POLICY, SUCH
          DISPUTE SHALL BE REFERRED TO ARBITRATION IN ACCORDANCE WITH THE
          FEDERAL ARBITRATION ACT FOR THE TIME BEING IN FORCE.
        </p>
        <p>
          <b>6.2</b> ARBITRATION SHALL BE CONDUCTED BY ONE (1) ARBITRATOR
          MUTUALLY APPOINTED BY THE COMPANY AND THE EMPLOYEE.
        </p>
        <p>
          <b>6.3</b> THE SEAT OF ARBITRATION SHALL BE DOVER, DELAWARE.
        </p>
        <p>
          <b>6.4</b> THE LANGUAGE OF THE ARBITRATION PROCEEDINGS AND OF ALL
          WRITTEN DECISIONS AND CORRESPONDENCE RELATING TO THE ARBITRATION SHALL
          BE ENGLISH.
        </p>
        <p>
          <b>6.5</b> THIS POLICY SHALL BE GOVERNED BY AND CONSTRUED IN
          ACCORDANCE WITH THE LAWS OF THE STATE OF DELAWARE. SUBJECT TO
          ARBITRATION, THE COURTS IN DOVER, DELAWARE SHALL HAVE EXCLUSIVE
          JURISDICTION IN RELATION TO ANY DISPUTES ARISING OUT OF OR IN
          CONNECTION WITH THIS POLICY.
        </p>

        <h2>7. EMAILS</h2>
        <p>
          <b>7.1</b> IF USER CHOOSE TO REGISTER FOR OUR PRODUCTS AND SERVICES,
          WE WILL SEND USER CERTAIN PROMOTIONAL EMAILS.
        </p>
        <p>
          <b>7.2</b> PROMOTIONAL EMAILS ADVERTISE OUR PRODUCTS AND SERVICES
          AND/OR THE PRODUCTS AND SERVICES OF OUR USERS AND AFFILIATES.
        </p>
        <p>
          <b>7.3</b> IF USER DO NOT WANT TO RECEIVE PROMOTIONAL EMAILS FROM US,
          USER MAY ELECT TO OPT-OUT OF RECEIVING PROMOTIONAL EMAILS AT ANY TIME
          AFTER REGISTERING BY E-MAILING US AT SUPPORT@JOBWAYS.COM, BY WRITING
          TO US AT THE ADDRESS CONTAINED HEREIN, OR BY HITTING THE "UNSUBSCRIBE"
          BUTTON AT THE BOTTOM OF ANY OF OUR E-MAILS.
        </p>

        <h2>8. INFORMATION</h2>
        <p>
          <b>8.1 GENERAL INFORMATION</b>
        </p>
        <p>
          <b>8.1.1</b> IN GENERAL, USER CAN VISIT THE WEB APPLICATION WITHOUT
          TELLING US WHO USER ARE OR REVEALING ANY PERSONALLY IDENTIFIABLE
          INFORMATION ABOUT USERSELF.
        </p>
        <p>
          <b>8.1.2</b> BY PROVIDING USER PERSONAL INFORMATION TO US, USER
          EXPLICITLY AGREE TO OUR COLLECTION AND USE OF SUCH INFORMATION AS
          DESCRIBED IN THIS POLICY.
        </p>
        <p>
          <b>8.1.3</b> IF USER CHOOSE TO REGISTER, AND ARE 13 YEARS OF AGE OR
          OLDER, WE WILL COLLECT AND PROCESS SUCH INFORMATION FROM USER,
          INCLUDING BUT NOT LIMITED TO THE BELOW MENTIONED:
        </p>
        <p>
          <b>8.1.4</b> INFORMATION THAT USER PROVIDE TO US BY FILLING IN FORMS,
          WHICH INCLUDES CONTACT INFORMATION SUCH AS NAME, EMAIL ADDRESS,
          MAILING ADDRESS, PHONE NUMBER, FINANCIAL INFORMATION, IF ANY, UNIQUE
          IDENTIFIERS SUCH AS PREFERENCES INFORMATION SUCH AS FAVORITES LISTS,
          TRANSACTION HISTORY.
        </p>
        <p>
          <b>8.1.5</b> INFORMATION THAT USER PROVIDE WHEN USER WRITE DIRECTLY TO
          US (INCLUDING BY E-MAIL).
        </p>
        <p>
          <b>8.1.6</b> INFORMATION THAT USER PROVIDE TO US BY WRITING ON OUR
          BLOGS.
        </p>
        <p>
          <b>8.1.7</b> INFORMATION RELATING TO LOGS IS AUTOMATICALLY REPORTED BY
          USER BROWSER EACH TIME USER ACCESS OUR WEB APPLICATION.
        </p>
        <p>
          <b>8.1.8</b> WHEN USER USE THE WEB APPLICATION, OUR SERVERS
          AUTOMATICALLY RECORD CERTAIN INFORMATION THAT USER WEB BROWSER SENDS
          WHENEVER USER VISIT ANY WEB APPLICATION.
        </p>
        <p>
          <b>8.1.9</b> THESE SERVER LOGS MAY INCLUDE INFORMATION SUCH AS USER
          WEB REQUEST, INTERNET PROTOCOL (IP) ADDRESS, BROWSER TYPE, REFER
          RING/EXIT PAGES AND URLS, NUMBER OF CLICKS, DOMAIN NAMES, LANDING
          PAGES, PAGES VIEWED, AND OTHER SUCH INFORMATION.
        </p>
        <p>
          <b>8.1.10</b> WE USE THIS INFORMATION, WHICH DOES NOT IDENTIFY USERS,
          TO ANALYZE TRENDS AND TO GATHER DEMOGRAPHIC INFORMATION ABOUT THE USER
          BASE AS A WHOLE.
        </p>
        <p>
          <b>8.1.11</b> WE DO NOT LINK THIS AUTOMATICALLY-COLLECTED DATA TO
          PERSONALLY IDENTIFIABLE INFORMATION.
        </p>
        <p>
          <b>8.1.12</b> WE MAY COLLECT INFORMATION ABOUT USER GENERAL INTERNET
          USAGE BY USING A COOKIE FILE WHICH IS STORED ON THE HARD DRIVE OF USER
          COMPUTER.
        </p>
        <p>
          <b>8.1.13</b> COOKIES HELP US IMPROVE OUR WEB APPLICATION AND DELIVER
          A BETTER AND PERSONALIZED SERVICE.
        </p>
        <p>
          <b>8.1.14</b> COOKIES ENABLE US:
        </p>
        <p>
          <b>8.1.15</b> TO ESTIMATE OUR USER’S USAGE SIZE AND PATTERN.
        </p>
        <p>
          <b>8.1.16</b> TO STORE INFORMATION ABOUT USER PREFERENCES, AND ALLOW
          US TO CUSTOMIZE OUR WEB APPLICATION ACCORDING TO USER INTERESTS.
        </p>
        <p>
          <b>8.1.17</b> TO RECOGNIZE USER WHEN USER RETURN TO OUR WEB
          APPLICATION.
        </p>

        <h2>
          <b>8.2</b> PERSONAL INFORMATION CORRECTIONS
        </h2>
        <p>
          <b>8.2.1</b> USER CAN CONTACT US IF USER NOTICE THAT THE INFORMATION
          WE ARE HOLDING IS INCORRECT OR INCOMPLETE.
        </p>
        <p>
          <b>8.2.2</b> PLEASE SEND US A MAIL TO SUPPORT@JOBWAYS.COM
        </p>

        <h2>
          <b>8.3</b> RETENTION OF INFORMATION
        </h2>
        <p>
          <b>8.3.1</b> WE WILL KEEP PERSONAL INFORMATION OF OUR USERS FOR AS
          LONG AS THEY ARE REGISTERED SUBSCRIBERS OR USERS OF OUR PRODUCTS AND
          SERVICES, AND AS PERMITTED BY LAW.
        </p>

        <h2>
          <b>8.4</b> SHARING USER PERSONAL INFORMATION
        </h2>
        <p>
          <b>8.4.1</b> IF USER HAVE PURCHASED PRODUCTS, WE MAY ASK USER FOR
          TESTIMONIAL, WE WILL DISPLAY USER TESTIMONIAL ON OUR WEB APPLICATION,
          PHOTOS AND VIDEOS OR IN OUR SOCIAL MEDIA CHANNELS SUCH AS FACEBOOK,
          USERTUBE OR FLICKR AND OTHERS.
        </p>
        <p>
          <b>8.4.2</b> USER SHOULD BE AWARE THAT USER PUBLICLY IDENTIFIABLE
          INFORMATION COULD BE USED TO SEND USER PROMOTIONAL, UNSOLICITED
          MESSAGES.
        </p>
        <p>
          <b>8.4.3</b> WE ARE NOT RESPONSIBLE FOR USER PERSONAL INFORMATION
          WHICH USER HAVE CHOSEN TO DISPLAY.
        </p>
        <p>
          <b>8.4.4</b> IF USER DON'T WANT US TO FEATURE USER
          PICTURES/TESTIMONIALS ON OUR WEBWEB APPLICATION OR ON OUR SOCIAL MEDIA
          CHANNELS, USER CAN WRITE A MAIL TO SUPPORT@JOBWAYS.COM
        </p>

        <h2>
          <b>9</b>. INTERNATIONAL USERS
        </h2>
        <p>
          <b>9.1</b> THIS WEB APPLICATION IS OPERATED IN THE UNITED STATES. IF
          USERS ARE VISITING FROM OTHER REGIONS WITH LAWS GOVERNING DATA
          COLLECTION AND USE THAT MAY DIFFER FROM U.S. LAW, PLEASE NOTE THAT
          USERS ARE TRANSFERRING THEIR PERSONAL DATA TO THE UNITED STATES, WHICH
          DOES NOT HAVE THE SAME DATA PROTECTION LAWS. BY PROVIDING PERSONAL
          DATA, USERS CONSENT TO:
        </p>
        <p>
          <b>9.2</b> THE USE OF THEIR PERSONAL DATA FOR THE USES IDENTIFIED
          ABOVE IN ACCORDANCE WITH THIS PRIVACY POLICY AND THE TRANSFER OF THEIR
          PERSONAL DATA TO THE UNITED STATES AS INDICATED ABOVE.
        </p>
        <p>
          <b>9.3</b> THE COMPANY IS ALSO NOT MAKING ANY REPRESENTATION THAT THE
          CONTENT CONTAINED ON THE PRODUCT IS APPROPRIATE OR TO BE USED OR
          ACCESSED OUTSIDE OF THE UNITED STATES, AND USER USE OR ACCESS OF THE
          WEB APPLICATION FROM OUTSIDE THE UNITED STATES IS AT USERS’ OWN RISK,
          AND USERS ARE RESPONSIBLE FOR COMPLIANCE WITH THE LAWS OF SUCH
          JURISDICTION.
        </p>

        <h2>
          <b>10</b>. LINKS & HYPERLINKS
        </h2>
        <p>
          <b>10.1</b> THIS WEB APPLICATION MAY CONTAIN HYPERLINKS TO OTHER WEB
          SITES.
        </p>
        <p>
          <b>10.2</b> THE COMPANY CANNOT AND DOES NOT CONTROL THE PRIVACY
          PRACTICES OF ANY SUCH SITES.
        </p>
        <p>
          <b>10.3</b> USER SHOULD REVIEW THE PRIVACY POLICY ON ANY WEBSITE WHERE
          USER MAY SUBMIT PERSONAL INFORMATION BEFORE PROVIDING IT TO ANY WEB
          SITE.
        </p>

        <h2>
          <b>11</b>. SECURITY
        </h2>
        <p>
          <b>11.1</b> ALL INFORMATION USER PROVIDE TO US IS STORED ON OUR SECURE
          SERVERS.
        </p>
        <p>
          <b>11.2</b> AS A REGISTERED USER WITH AN ACCOUNT AND A PASSWORD, USER
          ARE RESPONSIBLE FOR KEEPING USER PASSWORD CONFIDENTIAL.
        </p>
        <p>
          <b>11.3</b> WE TAKE COMMERCIALLY REASONABLE SAFEGUARDS TO PROTECT AND
          PRESERVE THE INTEGRITY AND SECURITY OF USER PERSONAL INFORMATION
          SUBMITTED TO US, BOTH DURING TRANSMISSION AND ONCE WE RECEIVE IT
          AGAINST LOSS, THEFT, UNAUTHORISED ACCESS, DISCLOSURE, REPRODUCTION,
          USE OR AMENDMENT.
        </p>
        <p>
          <b>11.4</b> NO METHOD OF TRANSMISSION OVER THE INTERNET, OR METHOD OF
          ELECTRONIC STORAGE, IS 100% SECURE.
        </p>
        <p>
          <b>11.5</b> THEREFORE, WHILE WE STRIVE TO USE COMMERCIALLY REASONABLE
          MEANS TO PROTECT USER PERSONAL INFORMATION, WE ASSUME NO LIABILITY FOR
          ANY DISCLOSURE OF INFORMATION DUE TO ERRORS IN TRANSMISSION,
          UNAUTHORISED THIRD PARTY ACCESS OR OTHER ACTS OF THIRD PARTIES, OR
          ACTS OR OMISSIONS BEYOND OUR REASONABLE CONTROL AND USER AGREE THAT
          USER WILL NOT HOLD US RESPONSIBLE FOR ANY BREACH OF SECURITY UNLESS
          SUCH BREACH HAS BEEN CAUSED AS A DIRECT RESULT OF OUR GROSS NEGLIGENCE
          OR WILFUL DEFAULT.
        </p>
        <p>
          <b>11.6</b> IF WE LEARN OF A SECURITY SYSTEMS BREACH WE MAY ATTEMPT TO
          NOTIFY USER ELECTRONICALLY SO THAT USER CAN TAKE APPROPRIATE
          PROTECTIVE STEPS.
        </p>
        <p>
          <b>11.7</b> BY USING THIS WEB APPLICATION OR PROVIDING PERSONAL
          INFORMATION TO US USER AGREE THAT WE CAN COMMUNICATE WITH USER
          ELECTRONICALLY REGARDING SECURITY, PRIVACY, AND ADMINISTRATIVE ISSUES
          RELATING TO USER USE OF THIS SITE.
        </p>
        <p>
          <b>11.8</b> WE MAY POST A NOTICE ON OUR WEB APPLICATION IF A SECURITY
          BREACH OCCURS.
        </p>
        <p>
          <b>11.9</b> WE MAY ALSO SEND AN EMAIL TO USER AT THE EMAIL ADDRESS
          USER HAVE PROVIDED TO US IN THESE CIRCUMSTANCES.
        </p>
        <p>
          <b>11.10</b> DEPENDING ON WHERE USER LIVE, USER MAY HAVE A LEGAL RIGHT
          TO RECEIVE NOTICE OF A SECURITY BREACH IN WRITING.
        </p>

        <h2>
          <b>12</b>. WEB BEACONS
        </h2>
        <p>
          <b>12.1</b> WEB PAGES MAY CONTAIN AN ELECTRONIC FILE CALLED A WEB
          BEACON THAT ALLOWS A WEB APPLICATION TO COUNT USERS WHO HAVE VISITED
          THAT PAGE OR TO ACCESS CERTAIN COOKIES.
        </p>
        <p>
          <b>12.2</b> WE MAY USE WEB BEACONS IN THE FOLLOWING WAYS:
        </p>
        <p>
          <b>12.2.1</b> WE USE WEB BEACONS WITHIN THE WEB APPLICATION IN ORDER
          TO COUNT USERS AND TO RECOGNIZE USERS BY ACCESSING OUR COOKIES.
        </p>
        <p>
          <b>12.2.2</b> BEING ABLE TO ACCESS OUR COOKIES ALLOWS US TO
          PERSONALIZE USER EXPERIENCE.
        </p>
        <p>
          <b>12.2.3</b> IN GENERAL, ANY FILE SERVED AS PART OF A WEB PAGE,
          INCLUDING AN AD BANNER, CAN ACT AS A WEB BEACON.
        </p>

        <h2>
          <b>13</b>. CHANGE IN THIS PRIVACY POLICY
        </h2>
        <p>
          <b>13.1</b> WE MAY OCCASIONALLY UPDATE THIS PRIVACY POLICY.
        </p>
        <p>
          <b>13.2</b> WHEN WE DO, WE WILL ALSO REVISE THE "LAST UPDATED" DATE ON
          THE PRIVACY POLICY.
        </p>
        <p>
          <b>13.3</b> FOR CHANGES TO THIS PRIVACY POLICY THAT MAY BE MATERIALLY
          LESS RESTRICTIVE ON OUR USE OR DISCLOSURE OF PERSONAL INFORMATION USER
          HAVE PROVIDED TO US, WE WILL ATTEMPT TO OBTAIN USER CONSENT BEFORE
          IMPLEMENTING THE CHANGE.
        </p>
        <p>
          <b>13.4</b> WE ENCOURAGE USER TO PERIODICALLY REVIEW THIS PRIVACY
          POLICY TO STAY INFORMED ABOUT HOW WE ARE PROTECTING THE PERSONAL
          INFORMATION WE COLLECT.
        </p>
        <p>
          <b>13.5</b> USER’S CONTINUED USE OF THIS WEB APPLICATION CONSTITUTES
          USER AGREEMENT TO THIS PRIVACY POLICY AND ANY UPDATES.
        </p>

        <h2>CONTACT US</h2>
        <p style={{ fontWeight: "bold", fontSize: "16px" }}>
          JOBWAYS.COM BY JOBWAYS LLC
        </p>
        <p style={{ fontWeight: "bold", fontSize: "16px" }}>
          8 THE GREEN STE R · DOVER, DE 19901
        </p>
      </div>
    </div>
  );
};

export default Privacypolicy;
